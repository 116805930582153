import { createSelector } from '@reduxjs/toolkit'
import { addDays, isBefore } from 'date-fns'
import type { GameProduct } from '@patrianna/shared-patrianna-types/store/GameModule'
import type { ReduxState } from 'src/store/types'
import { getUserCreatedAtSelector } from 'store/modules/user/selectors'

const getCategorizedGames = (state: ReduxState) => {
  return state.games.games
}

const getAllGamesState = (state: ReduxState) => state.games.allGames

export const allVisibleGamesSelector = createSelector(getAllGamesState, (games) => games.filter((it) => !it.hidden))

const getGamesLoading = (state: ReduxState) => state.games.loading
const getGamesLoaded = (state: ReduxState) => state.games.loaded

const getGameId = (state: ReduxState, gameId: string) => gameId

export const allGamesSelector = createSelector(getAllGamesState, (games) => games)

export const gamesLoadingSelector = createSelector(getGamesLoading, (loading) => loading)
export const gamesLoadedSelector = createSelector(getGamesLoaded, (loaded) => loaded)

export const getAllGamesProductsSelector = createSelector(getCategorizedGames, (categorizedProducts) => {
  if (categorizedProducts) {
    return Object.values(categorizedProducts).reduce((acc, products) => {
      return acc.concat(products)
    }, [] as GameProduct[])
  }

  return []
})

export const getGamesWithoutLotterySelector = createSelector(getAllGamesProductsSelector, (games) =>
  games.filter((it) => it.type !== 'lottery')
)

export const getSlotProductsSelector = createSelector(getCategorizedGames, (allGames) =>
  allGames?.slots.filter((it) => !!it)
)

export const getRawTableProductsSelector = createSelector(getAllGamesProductsSelector, (games) => {
  return games?.filter((i) => i.tags.includes('table'))
})

export const getNewGamesSelector = createSelector(getAllGamesProductsSelector, (games) =>
  games.filter((it) => it.tags.includes('new'))
)

export const getScratchcardGamesSelector = createSelector(getAllGamesProductsSelector, (games) =>
  games.filter((it) => it.tags.includes('GCS'))
)

export const getExclusiveGCGamesSelector = createSelector(getAllGamesProductsSelector, (games) =>
  games.filter((it) => it.tags.includes('GCT'))
)

export const getTopGamesSelector = createSelector(getAllGamesProductsSelector, (games) =>
  games.filter((it) => it.tags.includes('top'))
)

export const getMegawaysGamesSelector = createSelector(getAllGamesProductsSelector, (games) =>
  games.filter((it) => it.tags.includes('Meg'))
)

export const getPlayTheFeatureSelector = createSelector(getAllGamesProductsSelector, (games) =>
  games.filter((it) => it.tags.includes('PTF'))
)

export const getRecommendedGamesSelector = createSelector(
  getAllGamesProductsSelector,
  getUserCreatedAtSelector,
  (games, userCreatedAt) => {
    const today = new Date()
    const threeDaysAfrterUserCreated = addDays(new Date(userCreatedAt), 3)

    if (isBefore(today, threeDaysAfrterUserCreated)) {
      return [
        'hot-triple-sevens-special',
        'vs10chkchase',
        'vs15diamond',
        'disco-beats',
        'black-wolf',
        'sun-of-egypt-2',
        'great-panda',
      ].reduce((acc: any, code: string) => {
        const game = games.find((c) => c.code === code)
        if (game) {
          acc.push(game)
        }

        return acc
      }, [])
    }

    return []
  }
)

export const getClassicGamesSelector = createSelector(getAllGamesProductsSelector, (games) =>
  games.filter((it) => it.tags.includes('Cla'))
)
export const getEpicWinsGamesSelector = createSelector(getAllGamesProductsSelector, (games) =>
  games.filter((it) => it.tags.includes('Epi'))
)

export const getTopGamesByCountSelector = createSelector(
  getTopGamesSelector,
  (_: ReduxState, numOfGames: number) => numOfGames,
  (games, numOfGames) => {
    const allTopGames = games.filter((it) => it.tags.includes('top'))
    const topGamesByCount = [...allTopGames.slice(0, numOfGames)]

    return topGamesByCount
  }
)

export const getProgJackpotsSelector = createSelector(getAllGamesProductsSelector, (games) =>
  games.filter((it) => it.tags.includes('prog_jackpot'))
)

export const getHoldAndWinGamesSelector = createSelector(getAllGamesProductsSelector, (games) =>
  games.filter((it) => it.tags.includes('HAW'))
)

export const getHalloweenGamesSelector = createSelector(getAllGamesProductsSelector, (games) =>
  games.filter((it) => it.tags.includes('HAL'))
)

export const getVisibleTableGamesSelector = createSelector(getRawTableProductsSelector, (games) => games || [])

export const getGameByIdSelector = createSelector(getAllGamesProductsSelector, getGameId, (games, gameId: string) => {
  return games?.find((it) => it.code === gameId)
})

export const getUnlockedSlotsGamesSelector = createSelector(getSlotProductsSelector, (slots) => {
  if (slots) {
    return slots.filter((it) => it.unlocked)
  }

  return null
})

export const getFirstUnlockedSlotSelector = createSelector(getSlotProductsSelector, (games) => {
  if (games && games.length > 0) return games[0]

  return null
})

export const getBingoGamesSelector = createSelector(getAllGamesProductsSelector, (allGames) => {
  const bingoGames = allGames.filter((i) => i.type === 'bingo')

  // quik fix for bingo games
  const hardRooms = [{ title: 'hollywood' }, { title: 'empire_state' }]

  return [...(bingoGames || []), ...hardRooms] as GameProduct[]
})

export const getProviderGamesSelector = createSelector(
  getAllGamesProductsSelector,
  (_: ReduxState, provider: string) => provider,
  (games, provider) => games.filter((it) => it.provider === provider || it.supplier === provider)
)
