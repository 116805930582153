import { createSelector } from '@reduxjs/toolkit'
import type { ReduxState } from 'src/store/types'
import {
  getItemFormLocalStorageSelector,
  DAILY_REWARD_MODAL_LAST_TIME_SHOWN,
  IS_SIGN_UP_SESSION,
} from 'utils/localStorage'

const getDialogStack = (state: ReduxState) => state.dialog.stack
export const getDialogLightBackdropModeSelector = (state: ReduxState) => state.dialog.lightBackdropMode

export const getDialogStackSelector = createSelector(getDialogStack, (stack) => stack)

export const getDialogVisibilitySelector = createSelector(getDialogStackSelector, (stack) => stack.length > 0)

export const getActiveDialogProps = createSelector(getDialogStackSelector, (stack) => {
  return stack[stack.length - 1]
})

export const getDialogVisibilityByNameSelector = createSelector(
  getDialogStackSelector,
  (_: ReduxState, modalName: string) => modalName,
  (dialogsStack, modalName) => {
    const index = dialogsStack.findIndex((dialog) => dialog.modalName === modalName)

    return index !== -1
  }
)

export const getIsMenuOpenedSelector = (state: ReduxState) => state.dialog.isMenuOpened
export const dailyRewardModalLastTimeShownSelector = (state: ReduxState) =>
  getItemFormLocalStorageSelector(state, DAILY_REWARD_MODAL_LAST_TIME_SHOWN, false)
export const isSignUpSessionSelector = (state: ReduxState) =>
  getItemFormLocalStorageSelector(state, IS_SIGN_UP_SESSION, false)
