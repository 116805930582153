import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { SessionDialogs } from '@patrianna/shared-patrianna-types/store/SessionDialogsModule'
import type { DialogNames, SeesionDialogsStore } from 'store/types'

const initialState: SeesionDialogsStore = {
  legalUpdate: false,
  dailyBonuses: false,
  myStashWin: false,
  offers: false,
  freeSpins: false,
  historySessionDialogs: [],
}

export const sessionDialogsSlice = createSlice({
  name: 'sessionDialogs',
  initialState,
  reducers: {
    waitingForSessionDialogsData: (state, action: PayloadAction<SessionDialogs>) => {
      state.offers = action.payload.offers ?? state.offers
      state.legalUpdate = action.payload.legalUpdate ?? state.legalUpdate
      state.dailyBonuses = action.payload.dailyBonuses ?? state.dailyBonuses
      state.myStashWin = action.payload.myStashWin ?? state.myStashWin
      state.freeSpins = action.payload.freeSpins ?? state.freeSpins
    },

    setHistorySessionDialogs: (state, action: PayloadAction<{ modalName: DialogNames }>) => {
      const uniqueItems = [...new Set([...state.historySessionDialogs, action.payload.modalName])]
      state.historySessionDialogs = uniqueItems
    },

    removeFromHistorySessionDialogs: (state, action: PayloadAction<{ modalName: DialogNames }>) => {
      state.historySessionDialogs = state.historySessionDialogs.filter((dialog) => dialog !== action.payload.modalName)
    },
  },
})

export type ActionsTypes = ReturnType<InferValueTypes<typeof sessionDialogsSlice.actions>>
export const { actions } = sessionDialogsSlice
